import AiOutlineGlobalSvg from "./svgs/AiOutlineGlobal.svg";
import BsShieldLockSvg from "./svgs/BsShieldLock.svg";
import FcFinePrintSvg from "./svgs/FcFinePrint.svg";
import FcHomeSvg from "./svgs/FcHome.svg";
import IoCheckmarkSvg from "./svgs/IoCheckmark.svg";
import IoCloseSvg from "./svgs/IoClose.svg";
import IoFlagSvg from "./svgs/IoFlag.svg";
import IoSettingsOutlineSvg from "./svgs/IoSettingsOutline.svg";
import IoStatsChartSvg from "./svgs/IoStatsChart.svg";
import LuBellSvg from "./svgs/LuBell.svg";
import LuBellDotSvg from "./svgs/LuBellDot.svg";
import LuClipboardCheckSvg from "./svgs/LuClipboardCheck.svg";
import LuClipboardCopySvg from "./svgs/LuClipboardCopy.svg";
import LuMinusSvg from "./svgs/LuMinus.svg";
import LuPlusSvg from "./svgs/LuPlus.svg";
import LuSearchSvg from "./svgs/LuSearch.svg";
import MdInfoSvg from "./svgs/MdInfo.svg";
import PiCaretDownBoldSvg from "./svgs/PiCaretDownBold.svg";
import PiCaretLeftBoldSvg from "./svgs/PiCaretLeftBold.svg";
import PiCaretRightBoldSvg from "./svgs/PiCaretRightBold.svg";
import PiCaretUpBoldSvg from "./svgs/PiCaretUpBold.svg";
import TbExternalLinkSvg from "./svgs/TbExternalLink.svg";
import TbMapPinSvg from "./svgs/TbMapPin.svg";
import TiStarOutlineSvg from "./svgs/TiStarOutline.svg";

export type TIconId =
  | "website"
  | "shield-lock"
  | "fine-print"
  | "home"
  | "checkmark"
  | "close"
  | "flag"
  | "settings"
  | "stats-chart"
  | "bell"
  | "bell-dot"
  | "clipboard-check"
  | "clipboard-copy"
  | "shell-protocol"
  | "minus"
  | "plus"
  | "search"
  | "info"
  | "caret-down"
  | "caret-left"
  | "caret-right"
  | "caret-up"
  | "external-link"
  | "map-pin"
  | "star";

export interface IDaoAsset {
  id: TIconId;
  size: number;
  color?: string;
}

export default function IconAsset(props: IDaoAsset) {
  const { id, size, color } = props;

  switch (id) {
    case "website":
      return <AiOutlineGlobalSvg fill={color} width={size} height={size} />;
    case "shield-lock":
      return <BsShieldLockSvg fill={color} width={size} height={size} />;
    case "fine-print":
      // already colored
      return <FcFinePrintSvg width={size} height={size} />;
    case "home":
      // already colored
      return <FcHomeSvg width={size} height={size} />;
    case "checkmark":
      return <IoCheckmarkSvg stroke={color} width={size} height={size} />;
    case "close":
      return <IoCloseSvg fill={color} width={size} height={size} />;
    case "flag":
      return <IoFlagSvg width={size} height={size} />;
    case "settings":
      // using default colors
      return <IoSettingsOutlineSvg width={size} height={size} />;
    case "stats-chart":
      return <IoStatsChartSvg fill={color} width={size} height={size} />;
    case "bell":
      // using default colors
      return <LuBellSvg width={size} height={size} />;
    case "bell-dot":
      // using default colors
      return <LuBellDotSvg width={size} height={size} />;
    case "clipboard-check":
      return <LuClipboardCheckSvg stroke={color} width={size} height={size} />;
    case "clipboard-copy":
      return <LuClipboardCopySvg stroke={color} width={size} height={size} />;
    case "minus":
      // using default colors
      return <LuMinusSvg width={size} height={size} />;
    case "plus":
      // using default colors
      return <LuPlusSvg width={size} height={size} />;
    case "search":
      return <LuSearchSvg stroke={color} width={size} height={size} />;
    case "info":
      return <MdInfoSvg fill={color} width={size} height={size} />;
    case "caret-down":
      return <PiCaretDownBoldSvg fill={color} width={size} height={size} />;
    case "caret-left":
      return <PiCaretLeftBoldSvg fill={color} width={size} height={size} />;
    case "caret-right":
      return <PiCaretRightBoldSvg fill={color} width={size} height={size} />;
    case "caret-up":
      return <PiCaretUpBoldSvg fill={color} width={size} height={size} />;
    case "external-link":
      return <TbExternalLinkSvg stroke={color} width={size} height={size} />;
    case "map-pin":
      return <TbMapPinSvg stroke={color} width={size} height={size} />;
    case "star":
      return (
        <TiStarOutlineSvg
          stroke={color}
          fill={color}
          width={size}
          height={size}
        />
      );
    default:
      return null;
  }
}
