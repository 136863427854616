import ArbitrumSvg from "./svgs/arbitrum.svg";
import EthereumSvg from "./svgs/ethereum.svg";
import MaticSvg from "./svgs/matic.svg";

export type TNetworkId = "arbitrum" | "ethereum" | "matic";

export interface INetworkAsset {
  id: TNetworkId;
  size: number;
}

export default function NetworkAsset(props: INetworkAsset) {
  const { id, size } = props;

  switch (id) {
    case "arbitrum":
      return <ArbitrumSvg width={size} height={size} />;
    case "ethereum":
      return <EthereumSvg width={size} height={size} />;
    case "matic":
      return <MaticSvg width={size} height={size} />;
    default:
      return null;
  }
}
