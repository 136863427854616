import styles from "./styles.module.css";
import DaoAsset, { TDaoId } from "./Daos/index";
import IconAsset, { TIconId } from "./Icons";
import LogoAsset, { TLogoId } from "./Logos";
import NetworkAsset, { TNetworkId } from "./Networks";
import SupportAsset, { TSupportId } from "./Supports";
import PlaceholderSvg from "./placeholder.svg";

type TAsset = "dao" | "icon" | "logo" | "support" | "network" | "placeholder";

interface IAsset {
  type: TAsset;
  id: null | TDaoId | TIconId | TLogoId | TSupportId | TNetworkId;
  size: number;
  color?: string;
  asAvatar?: boolean;
}

export default function Asset(props: IAsset) {
  const { type, id, size, color = "", asAvatar = false } = props;

  const renderAsset = (type: TAsset) => {
    switch (type) {
      case "dao":
        return <DaoAsset id={id as TDaoId} size={size} />;
      case "icon":
        return <IconAsset id={id as TIconId} size={size} color={color} />;
      case "logo":
        return <LogoAsset id={id as TLogoId} size={size} color={color} />;
      case "support":
        return <SupportAsset id={id as TSupportId} size={size} />;
      case "network":
        return <NetworkAsset id={id as TNetworkId} size={size} />;
      case "placeholder":
        return <PlaceholderSvg width={size} height={size} />;
      default:
        return null;
    }
  };

  if (asAvatar) {
    return <div className={styles.asset}>{renderAsset(type)}</div>;
  } else {
    return <>{renderAsset(type)}</>;
  }
}
