import ArbitrumFullSvg from "./svgs/arbitrum-full.svg";
import EnsFullSvg from "./svgs/ens-full.svg";
import MaskFullSvg from "./svgs/mask-full.svg";
import PushFullSvg from "./svgs/push-full.svg";

export type TSupportId = "arbitrum" | "ens" | "mask" | "push";

export interface ISupportAsset {
  id: TSupportId;
  size: number;
}

export default function SupportAsset(props: ISupportAsset) {
  const { id, size } = props;

  switch (id) {
    case "arbitrum":
      return <ArbitrumFullSvg width={192} height={size} />;
    case "ens":
      return <EnsFullSvg width={112} height={size} />;
    case "mask":
      return <MaskFullSvg width={168} height={size} />;
    case "push":
      return <PushFullSvg width={128} height={size} />;
    default:
      return null;
  }
}
