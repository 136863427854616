import DhiveSvg from "./svgs/dhive.svg";
import DiscordSvg from "./svgs/discord.svg";
import EnsSvg from "./svgs/ens.svg";
import EthereumSvg from "./svgs/ethereum.svg";
import FarcasterSvg from "./svgs/farcaster.svg";
import GithubSvg from "./svgs/github.svg";
import HeySvg from "./svgs/hey.svg";
import LensSvg from "./svgs/lens.svg";
import MetaMaskSvg from "./svgs/metamask.svg";
import RedditSvg from "./svgs/reddit.svg";
import TelegramSvg from "./svgs/telegram.svg";
import TwitterSvg from "./svgs/twitter.svg";
import UnstoppableDomainsSvg from "./svgs/unstoppabledomains.svg";

export type TLogoId =
  | "dhive"
  | "discord"
  | "ens"
  | "ethereum"
  | "farcaster"
  | "github"
  | "hey"
  | "lens"
  | "metamask"
  | "reddit"
  | "telegram"
  | "twitter"
  | "unstoppabledomains"
  | "shapeshift"
  | "shell-protocol"
  | "sperax"
  | "swapr"
  | "uniswap";

export interface ILogoAsset {
  id: TLogoId;
  size: number;
  color?: string;
}

export default function LogoAsset(props: ILogoAsset) {
  const { id, size, color } = props;

  switch (id) {
    case "dhive":
      // already colored
      return <DhiveSvg width={size} height={size} />;
    case "discord":
      return <DiscordSvg fill={color} width={size} height={size} />;
    case "ens":
      return <EnsSvg fill={color} width={size} height={size} />;
    case "ethereum":
      // already colored
      return <EthereumSvg width={size} height={size} />;
    case "farcaster":
      return <FarcasterSvg fill={color} width={size} height={size} />;
    case "github":
      return (
        <GithubSvg stroke={color} fill={color} width={size} height={size} />
      );
    case "hey":
      return <HeySvg fill={color} width={size} height={size} />;
    case "lens":
      return <LensSvg fill={color} width={size} height={size} />;
    case "metamask":
      // already colored
      return <MetaMaskSvg width={size} height={size} />;
    case "reddit":
      return <RedditSvg fill={color} width={size} height={size} />;
    case "telegram":
      return <TelegramSvg fill={color} width={size} height={size} />;
    case "twitter":
      return <TwitterSvg fill={color} width={size} height={size} />;
    case "unstoppabledomains":
      return <UnstoppableDomainsSvg fill={color} width={size} height={size} />;
    default:
      return null;
  }
}
